import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { testimonialData, testimonialHeadData } from "../data/testimonialData";
import left from "../assets/testimonial/quote-sign-left.png";
import right from "../assets/testimonial/quote-sign-right.png";

SwiperCore.use([Autoplay, Pagination]);

function Testimonial() {
  return (
    <section id="testimonials" className="section-bg">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3>{testimonialHeadData.title}</h3>
        </header>

        <div
          className="testimonials-slider swiper-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Swiper
            speed={600}
            loop={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            slidesPerView={"auto"}
            pagination={{ type: "bullets", clickable: true }}
          >
            {testimonialData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="testimonial-item">
                  <img
                    src={item.imgUrl}
                    className="testimonial-img"
                    alt={item.imgAlt}
                  />
                  <h3>{item.name}</h3>
                  <h4>{item.occupation}</h4>
                  <p>
                    <img src={left} className="quote-sign-left" alt="" />
                    {item.comments}
                    <img src={right} className="quote-sign-right" alt="" />
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
