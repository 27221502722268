const factsData = {
  title: "热点展示",
  content: `用真诚服务客人，以优质回报市场，得助教育拥有业内良好的口碑，
  是大量学生的首选机构`,
};

const counterData = [
  {
    id: 1,
    end: 2262,
    duration: 1,
    name: "服务客人数量",
  },
  {
    id: 2,
    end: 3472,
    duration: 1,
    name: "业务办理数量",
  },
  {
    id: 3,
    end: 9452,
    duration: 1,
    name: "技能培训小时",
  },
  {
    id: 4,
    end: 31,
    duration: 1,
    name: "团队成员数量",
  },
];

const infoHeadData = {
  title: `选择得助，追求卓越`,
  content: `当您全面了解了得助的实力和服务质量之后，您会更加清晰选择我们的理由，在追求卓越的过程中，
  请允许我们参与进来，让您的留学旅程更加完美`,
};

const infoData = [
  {
    id: "01",
    title: "留学管家式的全方位服务",
    content: `得助教育是澳洲为数不多的全方位综合管家式留学机构，为赴澳留学的同学们提供从学业，生活，
    到娱乐，培训，求职，创业，移民，以及回国过度安排等一系列服务，真正把你的一切安排的妥妥帖帖`,
    target: "accordion-list-1",
  },
  {
    id: "02",
    title: "高质量，高效率的行政团队",
    content: `由得助 CEO 亲自带队，培训的精英行政团队，保证着所有服务流程的无间断的运行，
    任何事件状态的更新，我们都会在第一时间处理妥当。来得助，你将感受到的只有稳和放心`,
    target: "accordion-list-2",
  },
  {
    id: "03",
    title: "市中心最便利的地理位置",
    content: `得助教育位于布里斯班市中心 Edwards 大街上，David Jones 旁边，是众多交通枢纽的汇集地，便于定位，
    可以轻松服务生活在各个社区的留学生，无论是前来咨询还是参加培训课程，都省去了同学们的公交之苦`,
    target: "accordion-list-3",
  },
];

export { factsData, counterData, infoHeadData, infoData };
