import React from "react";
import callToActionData from "../data/callToActionData";

function CallToAction() {

  return (
    <section id="call-to-action">
      <div className="container text-center" data-aos="zoom-in">
        <h3>{callToActionData.title}</h3>
        <p>{callToActionData.content}</p>
        <a
          className="cta-btn"
          data-bs-toggle="modal"
          data-bs-target="#callToActionModal"
        >
          {callToActionData.btnText}
        </a>
      </div>
    </section>
  );
}

export default CallToAction;
