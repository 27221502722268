import React, { useEffect, useState, useRef } from "react";
import Isotope from "isotope-layout";
import Glightbox from "glightbox";
import {
  portfolioData,
  filterData,
  portfolioHeadData,
} from "../data/portfolioData";

function Portfolio({ reference }) {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [filter, setFilter] = useState(filterData);

  new Glightbox({
    selector: ".portfolio-lightbox",
  });

  useEffect(() => {
    isotope.current = new Isotope(".portfolio-container", {
      itemSelector: ".portfolio-item",
    });
    // cleanup
    return () => isotope.current.destory();
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key, id) => {
    setFilterKey(key);
    const newFilter = filter.map((item) => {
      item.active = false;
      if (item.id === id) item.active = true;
      return item;
    });
    setFilter(newFilter);
  };
  return (
    <section id="portfolio" className="section-bg" ref={reference}>
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3 className="section-title">{portfolioHeadData.title}</h3>
        </header>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12">
            <ul id="portfolio-flters">
              {filter.map((item) => (
                <li
                  key={item.id}
                  data-filter={item.filter}
                  className={item.active ? "filter-active" : undefined}
                  onClick={() => handleFilterKeyChange(item.filter, item.id)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {portfolioData.map((item) => (
            <div
              key={item.id}
              className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}
            >
              <div className="portfolio-wrap">
                <figure>
                  <img
                    src={item.imgUrl}
                    className="img-fluid"
                    alt={item.imgAlt}
                  />
                  <a
                    href={item.imgUrl}
                    data-lightbox="portfolio"
                    data-title={item.title}
                    className="link-preview portfolio-lightbox"
                    data-gallery="portfolioGallery"
                  >
                    <i className="bi bi-plus"></i>
                  </a>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#callToActionModal"
                    className="link-details"
                    title="More Details"
                  >
                    <i className="bi bi-link"></i>
                  </a>
                </figure>

                <div className="portfolio-info">
                  <h4>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#callToActionModal"
                    >
                      {item.title}
                    </a>
                  </h4>
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
