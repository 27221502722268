import React from "react";
import NavBar from "./NavBar";

function Header({ scroll, refs }) {
  return (
    <header
      id="header"
      className={`fixed-top d-flex align-items-center header-transparent ${
        scroll > 100 && "header-scrolled"
      }`}
    >
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-11 d-flex align-items-center justify-content-between">
            <h1 className="logo">
              <a href="/">
                <span className="logo-text">
                  Distinct<span className="sub-logo">得助教育</span>
                </span>
                edU
              </a>
            </h1>

            {/* <a href="index.html" className="logo">
              <img src="assets/img/logo.png" alt="" className="img-fluid" />
            </a> */}

            <NavBar scroll={scroll} refs={refs} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
