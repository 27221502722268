import React from "react";
import contactData from "../data/contactData";
import ContactForm from "./ContactForm";

function Contact({ reference }) {
  return (
    <section id="contact" className="section-bg" ref={reference}>
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>{contactData.title}</h3>
          <p>{contactData.content}</p>
        </div>
      </div>
      <div className="map" data-aos="fade-up">
        <iframe
          style={{ border: "0", width: "100%", height: "350px" }}
          src={contactData.mapUrl}
          frameBorder="0"
          allowFullScreen
          title="map"
        ></iframe>
      </div>
      <div className="container mt-1" data-aos="fade-up">
        <div className="row contact-info">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="bi bi-geo-alt"></i>
              <h3>公司地址</h3>
              <address>{contactData.address}</address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-phone">
              <i className="bi bi-phone"></i>
              <h3>联系电话</h3>
              <p>
                <a href={`tel:${contactData.tel}`}>{contactData.phone}</a>
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <i className="bi bi-envelope"></i>
              <h3>联系邮箱</h3>
              <p>
                <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
              </p>
            </div>
          </div>
        </div>

        <div className="form">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default Contact;
