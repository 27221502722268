const navData = [
  {
    id: 1,
    name: "关于我们",
    target: "about",
  },
  {
    id: 2,
    name: "服务介绍",
  },
  {
    id: 3,
    name: "项目分享",
  },
  {
    id: 4,
    name: "培训课程",
  },
  {
    id: 5,
    name: "联系我们",
  },
];

const universityData = [
  {
    id: 1,
    name: "澳大利亚国立大学 (ANU)",
    link: "http://www.anu.edu.au/",
  },
  {
    id: 2,
    name: "阿德莱德大学 (UADEL)",
    link: "https://www.adelaide.edu.au/",
  },
  {
    id: 3,
    name: "墨尔本大学 (UMEL)",
    link: "http://www.unimelb.edu.au/",
  },
  {
    id: 4,
    name: "新南威尔士大学 (UNSW)",
    link: "https://www.unsw.edu.au/",
  },
  {
    id: 5,
    name: "昆士兰大学 (UQ)",
    link: "http://www.uq.edu.au/",
  },
  {
    id: 6,
    name: "悉尼大学 (USYD)",
    link: "http://sydney.edu.au/",
  },
  {
    id: 7,
    name: "西澳大学 (UWA)",
    link: "http://www.uwa.edu.au/",
  },
  {
    id: 8,
    name: "莫纳什大学 (Monash)",
    link: "http://www.monash.edu/",
  },
];

export { navData, universityData };
