import portfolioImg1 from "../assets/portfolio/uq-bg.jpg"
import portfolioImg2 from "../assets/portfolio/dedu-travel.jpg"
import portfolioImg3 from "../assets/portfolio/stock-bg.jpg"
import portfolioImg4 from "../assets/portfolio/us-bg.jpg"
import portfolioImg5 from "../assets/portfolio/data-bg.jpg"
import portfolioImg6 from "../assets/portfolio/unsw-bg.jpg"
import portfolioImg7 from "../assets/portfolio/dedu-party.jpg"
import portfolioImg8 from "../assets/portfolio/um-bg.jpg"
import portfolioImg9 from "../assets/portfolio/anu-bg.jpg"

const portfolioHeadData = {
  title: "项目分享",
  content: "",
};

const filterData = [
  {
    id: 1,
    filter: "*",
    name: "全部",
    active: true,
  },
  {
    id: 2,
    filter: "filter-educ",
    name: "留学",
    active: false,
  },
  {
    id: 3,
    filter: "filter-activity",
    name: "团建",
    active: false,
  },
  {
    id: 4,
    filter: "filter-training",
    name: "培训",
    active: false,
  },
];

const portfolioData = [
  {
    id: 1,
    filter: "filter-educ",
    imgUrl: portfolioImg1,
    imgAlt: "University of Queensland",
    title: "开启昆士兰大学申请 (UQ)",
    content: "成功办理澳洲八大之昆士兰大学申请，实现你的名校梦",
  },
  {
    id: 2,
    filter: "filter-activity",
    imgUrl: portfolioImg2,
    imgAlt: "Byron Bay",
    title: "得助学员拜伦湾 (Byron Bay) 团建",
    content: "留学的同时不要错过体会澳洲美丽风景的机会",
  },
  {
    id: 3,
    filter: "filter-training",
    imgUrl: portfolioImg3,
    imgAlt: "CFA Training",
    title: "注册金融分析师培训 (CFA)",
    content: "成功开设多期 CFA 培训课程，金融分析师考试无忧",
  },
  {
    id: 4,
    filter: "filter-educ",
    imgUrl: portfolioImg4,
    imgAlt: "University of Sydney",
    title: "世界名校悉尼大学申请 (USYD)",
    content: "成功办理澳洲八大之悉尼大学申请，实现你的名校梦",
  },
  {
    id: 5,
    filter: "filter-training",
    imgUrl: portfolioImg5,
    imgAlt: "Data Analytics Training",
    title: "数据分析 (DA/BA) 技能培训 ",
    content: "Excel/Python/SQL 数据培训课程，BA 速成之路",
  },
  {
    id: 6,
    filter: "filter-educ",
    imgUrl: portfolioImg6,
    imgAlt: "University of New South Wales",
    title: "启动新南威尔士大学申请 (UNSW)",
    content: "成功办理澳洲八大之新南威尔士大学",
  },
  {
    id: 7,
    filter: "filter-activity",
    imgUrl: portfolioImg7,
    imgAlt: "Hot Port Function",
    title: "得助学员百人火锅宴团建",
    content: "感谢同学们的一路信任和陪伴",
  },
  {
    id: 8,
    filter: "filter-educ",
    imgUrl: portfolioImg8,
    imgAlt: "University of Melbourne",
    title: "澳洲第一墨尔本大学 (UMEL)",
    content: "成功办理澳洲八大之墨尔本大学",
  },
  {
    id: 9,
    filter: "filter-educ",
    imgUrl: portfolioImg9,
    imgAlt: "The Australian National University",
    title: "申请澳大利亚国立大学 (ANU)",
    content: "成功办理澳洲八大之澳国立大学",
  },
];

export { portfolioData, filterData, portfolioHeadData };
