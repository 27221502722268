import React from "react";
import Features from "./Features";
import About from "./About";
import Services from "./Services";
import CallToAction from "./CallToAction";
import Skills from "./Skills";
import Facts from "./Facts";
import Portfolio from "./Portfolio";
import Clients from "./Clients";
import Testimonial from "./Testimonial";
import Training from "./Training";
import Contact from "./Contact";
import Modal from "./Modal";

function Main({ scroll, refs, getStart }) {
  return (
    <main id="main">
      <Features reference={getStart}  />
      <About reference={refs[0]} />
      <Services reference={refs[1]} />
      <CallToAction />
      <Modal />
      <Skills scroll={scroll} />
      <Facts scroll={scroll} />
      <Portfolio reference={refs[2]} />
      <Clients />
      <Testimonial />
      <Training reference={refs[3]} />
      <Contact reference={refs[4]} />
    </main>
  );
}

export default Main;
