const trainingHeadData = {
  title: "培训课程",
  content: `凡在得助教育办理留学业务的同学均可优惠享受得助技能培训课程，充实同学们的课外生活，加强同学们的职场能力，
  提高相关专业的就业概率`,
};

const trainingData = [
  {
    id: 1,
    title: "财会培训课程",
    sup: "惠",
    subtitle: "10%",
    sub: " 限得助学员",
    items: [
      "基础会计原理，分录原理",
      "财务报表分析",
      "热门财会软件使用操作",
      "会计税务原理，税法",
      "注册会计师 (CPA) 考核培训",
    ],
    btnText: "马上咨询",
    featured: false,
    advanced: false,
    advancedText: "",
    classSetting: "",
  },
  {
    id: 2,
    title: "金融培训课程",
    sup: "惠",
    subtitle: "15%",
    sub: " 限得助学员",
    items: [
      "基础金融理论",
      "投资组合理论 (Portfolio Theory)",
      "资本预算 (Capital Budgeting) 分析",
      "金融数据分析，金融统计",
      "注册金融分析师 (CFA) 考核培训",
    ],
    btnText: "马上咨询",
    featured: false,
    advanced: false,
    advancedText: "",
    classSetting: "mt-4 mt-md-0",
  },
  {
    id: 3,
    title: "数据分析课程",
    sup: "惠",
    subtitle: "20%",
    sub: " 限得助学员",
    items: [
      "基础数据分析原理",
      "使用 Excel 进行数据分析",
      "Python 编程语言入门",
      "Python 函数库数据分析流程",
      "SQL 数据库询问语言",
      "数据分析应用统计学",
      "R 语言编程处理复杂数据分析",
      "行业数据分析案例实操",
      "面试题目分析，入行指导",
    ],
    btnText: "马上咨询",
    featured: true,
    advanced: false,
    advancedText: "",
    classSetting: "mt-4 mt-lg-0",
  },
  {
    id: 4,
    title: "应用开发课程",
    sup: "惠",
    subtitle: "5%",
    sub: " 限得助学员",
    items: [
      "基础网站应用开发原理",
      "UX/UI 美工交互设计",
      "前端开发语言 HTML, CSS",
      "前端编程语言 javaScript",
      "后端编程语言 php",
      "数据库构建，定义语言 SQL",
      "网端应用服务器部署",
    ],
    btnText: "马上咨询",
    featured: false,
    advanced: true,
    advancedText: "高阶课程",
    classSetting: "mt-4 mt-lg-0",
  },
];

export { trainingHeadData, trainingData };
