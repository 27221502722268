import React from "react";
import "animate.css/animate.css";
import { heroIndicator, heroData } from "../data/heroData";

function Hero({ reference }) {
  return (
    <section id="hero">
      <div className="hero-container">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <ol id="hero-carousel-indicators" className="carousel-indicators">
            {heroIndicator.map((item, index) => (
              <li
                key={item.id}
                data-bs-target="#heroCarousel"
                data-bs-slide-to={index}
                className={item.status}
              ></li>
            ))}
          </ol>

          <div className="carousel-inner" role="listbox">
            {heroData.map((item) => (
              <div key={item.id} className={`carousel-item ${item.status}`}>
                <img
                  src={item.imgSrc}
                  className="d-block carousel-img"
                  alt={item.title}
                ></img>
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      {item.title}
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      {item.content}
                    </p>
                    <a
                      onClick={() => {
                        window.scrollTo(0, reference.current.offsetTop - 60);
                      }}
                      className="
                        btn-get-started
                        scrollto
                        animate__animated animate__fadeInUp
                      "
                    >
                      {item.btnText}{" "}
                      <i className="bi bi-arrow-down-circle-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            ></span>
          </a>

          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            ></span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
