const skillsHeadData = {
  title: "我们的优势",
  content: `得助教育用过硬的经验，和认真负责的态度，为广大赴澳留学生护航。多年来我们积累了卓越的工作成果，
  并会继续为之坚持不懈的努力`,
};

const skillsData = [
  {
    id: 1,
    percentage: "100%",
    color: "bg-success",
    name: "院校申请成功率",
  },
  {
    id: 2,
    percentage: "98%",
    color: "bg-info",
    name: "入学学生毕业率",
  },
  {
    id: 3,
    percentage: "96%",
    color: "bg-warning",
    name: "签证申请下签率",
  },
  {
    id: 4,
    percentage: "93%",
    color: "bg-danger",
    name: "学员培训满意度",
  },
];

export { skillsData, skillsHeadData };
