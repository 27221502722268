import React from "react";
import { servicesHeadData, servicesData } from "../data/servicesData";

function Services({ reference }) {
  return (
    <section id="services" ref={reference}>
      <div className="container" data-aos="fade-up">
        <header className="section-header wow fadeInUp">
          <h3>{servicesHeadData.title}</h3>
          <p>{servicesHeadData.content}</p>
        </header>

        <div className="row">
          {servicesData.map((item) => (
            <div
              key={item.id}
              className="col-lg-4 col-md-6 box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon">
                <i className={item.icon}></i>
              </div>
              <h4 className="title">
                <a data-bs-toggle="modal" data-bs-target="#callToActionModal">
                  {item.title}
                </a>
              </h4>
              <p className="description">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
