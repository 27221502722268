import img1 from "../assets/clients/anu.png";
import img2 from "../assets/clients/mu.png";
import img3 from "../assets/clients/ua.png";
import img4 from "../assets/clients/umel.png";
import img5 from "../assets/clients/unsw.png";
import img6 from "../assets/clients/uq.png";
import img7 from "../assets/clients/usyd.png";
import img8 from "../assets/clients/uwa.png";

const clientsHeadData = {
  title: "相关合作院校",
  content: "",
};

const clientsData = [
  {
    id: 1,
    imgUrl: img1,
    imgAlt: "client 1",
  },
  {
    id: 2,
    imgUrl: img2,
    imgAlt: "client 2",
  },
  {
    id: 3,
    imgUrl: img3,
    imgAlt: "client 3",
  },
  {
    id: 4,
    imgUrl: img4,
    imgAlt: "client 4",
  },
  {
    id: 5,
    imgUrl: img5,
    imgAlt: "client 5",
  },
  {
    id: 6,
    imgUrl: img6,
    imgAlt: "client 6",
  },
  {
    id: 7,
    imgUrl: img7,
    imgAlt: "client 7",
  },
  {
    id: 8,
    imgUrl: img8,
    imgAlt: "client 8",
  },
];

export { clientsData, clientsHeadData };
