import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Main from "./components/Main";
import Footer from "./components/Footer";
import PositionCotroller from "./components/PositionCotroller";
import PreLoader from "./components/PreLoader";

function App() {
  const [scroll, setScroll] = useState(0);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);
  const trainingRef = useRef(null);
  const contactRef = useRef(null);
  const preloaderRef = useRef(null);
  const featuresRef = useRef(null);

  const sectionRefs = [
    aboutRef,
    servicesRef,
    portfolioRef,
    trainingRef,
    contactRef,
  ];

  useEffect(() => {
    // remove the issue of SameSite cookies
    // document.cookie = "promo_shown=1; SameSite=None; Secure";
    // remove preloader once all loaded
    document.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        preloaderRef.current.remove();
      }, 666);
    });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, [scroll]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    };
  }, [scroll]);

  return (
    <React.Fragment>
      <PreLoader reference={preloaderRef} />
      <Header scroll={scroll} refs={sectionRefs} />
      <Hero reference={featuresRef} />
      <Main scroll={scroll} refs={sectionRefs} getStart={featuresRef} />
      <Footer />
      <PositionCotroller scroll={scroll} />
    </React.Fragment>
  );
}

export default App;
