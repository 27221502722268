import React from "react";
import { trainingHeadData, trainingData } from "../data/trainingData";

function Training({ reference }) {
  return (
    <section id="training" ref={reference}>
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h3>{trainingHeadData.title}</h3>
          <p>{trainingHeadData.content}</p>
        </div>

        <div className="row pricing align-items-center">
          {trainingData.map((item) => (
            <div
              key={item.id}
              className="col-lg-3 col-md-6 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className={`box ${item.featured ? "featured" : ""}`}>
                {item.advanced ? (
                  <span className="advanced">{item.advancedText}</span>
                ) : (
                  ""
                )}
                <h3>{item.title}</h3>
                <h4>
                  <sup>{item.sup}</sup>
                  {item.subtitle}
                  <span>{item.sub}</span>
                </h4>
                <ul>
                  {item.items.map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
                </ul>
                <div className="btn-wrap">
                  <a
                    className="btn-buy"
                    data-bs-toggle="modal"
                    data-bs-target="#callToActionModal"
                  >
                    {item.btnText}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Training;
