import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import { clientsData, clientsHeadData } from "../data/clientsData";

SwiperCore.use([Autoplay, Pagination]);

function Clients() {
  return (
    <section id="clients">
      <div className="container" data-aos="zoom-in">
        <header className="section-header">
          <h3>{clientsHeadData.title}</h3>
        </header>
        <div className="clients-slider swiper-container">
          <div className="swiper-wrapper align-items-center">
            <Swiper
              spaceBetween={20}
              slidesPerView={6}
              loop={true}
              speed={400}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                type: "bullets",
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
            >
              {clientsData.map((item) => (
                <SwiperSlide key={item.id}>
                  <img
                    src={item.imgUrl}
                    className="img-fluid"
                    alt={item.imgAlt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
