import wechatQR from "../assets/footer/weixin.jpg";

export default {
  intro: `得助教育 (Distinctive Education) 是澳大利亚专业留学移民咨询中心，精英技能培训机构，
  拥有十年以上的行业资历。专业且庞大的咨询顾问和技能培训师团队，为同学们的澳洲梦开创不一样的前景。
  选择得助进入你留学的理想港湾，圆梦青春不再是一句空谈。`,
  partTwoTitle: "热点链接",
  links: [
    {
      id: 1,
      name: "主页",
      link: "/",
    },
    {
      id: 2,
      name: "关于我们",
      link: "#about",
    },
    {
      id: 3,
      name: "服务介绍",
      link: "#services",
    },
    {
      id: 4,
      name: "热点展示",
      link: "#facts",
    },
    {
      id: 5,
      name: "项目分享",
      link: "#portfolio",
    },
  ],
  portThreeTitle: "联系我们",
  contact: {
    address: `Suite 4 Level 7, 243 Edward Street,
      Brisbane City, QLD, Australia, 4000`,
    phone: "+61 0404 368 885",
    email: "info@distinctiveedu.com.au",
  },
  social: [
    {
      id: 1,
      name: "facebook",
      icon: "bi bi-facebook",
      link: "",
    },
    {
      id: 2,
      name: "linkedin",
      icon: "bi bi-linkedin",
      link: "",
    },
    {
      id: 3,
      name: "youtube",
      icon: "bi bi-youtube",
      link: "",
    },
    {
      id: 4,
      name: "twitter",
      icon: "bi bi-twitter",
      link: "",
    },
    {
      id: 5,
      name: "instagram",
      icon: "bi bi-instagram",
      link: "",
    },
  ],
  portFourTitle: "添加微信",
  callUp: `扫描下方二维码添加微信，我们的的客服人员会为您提供在线咨询服务，包含澳洲大学选课，
  院校咨询，留学，移民方案及其他技能培训计划`,
  imgUrl: wechatQR,
  imgAlt: "We Chat",
};
