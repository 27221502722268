import React from "react";
import featuresData from "../data/featuresData";

function Features({ reference }) {

  
  return (
    <section id="featured-services" ref={reference}>
      <div className="container-fluid">
        <div className="row">
          {featuresData.map((item) => (
            <div key={item.id} className={`col-lg-4 box ${item.bg}`}>
              <i className={item.icon}></i>
              <h4 className="title">
                <a href="#services">{item.title}</a>
              </h4>
              <p className="description">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;
