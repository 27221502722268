import React from "react";

function PositionCotroller({ scroll }) {
  return (
    <a
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className={`back-to-top d-flex align-items-center justify-content-center ${
        scroll > 100 && "active"
      }`}
    >
      <i className="bi bi-arrow-up-short"></i>
    </a>
  );
}

export default PositionCotroller;
