import React from "react";
import footerData from "../data/footerData";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-info">
              <h3>
                <a href="/">
                  <span className="logo-text">Distinct</span>edU
                </a>
              </h3>
              <p>{footerData.intro}</p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>{footerData.partTwoTitle}</h4>
              <ul>
                {footerData.links.map((item) => (
                  <li key={item.id}>
                    <i className="bi bi-chevron-right"></i>
                    <a href={item.link}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-contact">
              <h4>{footerData.portThreeTitle}</h4>
              <p>
                <strong>公司地址:</strong>
                <br />
                {footerData.contact.address}
                <br />
                <strong>联系电话:</strong> {footerData.contact.phone}
                <br />
                <strong>联系邮箱:</strong> {footerData.contact.email}
                <br />
              </p>

              <div className="social-links">
                {footerData.social.map((item) => (
                  <a key={item.id} href={item.link} className={item.name}>
                    <i className={item.icon}></i>
                  </a>
                ))}
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>{footerData.portFourTitle}</h4>
              <p>{footerData.callUp}</p>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <img
                  className="wechat"
                  src={footerData.imgUrl}
                  alt={footerData.imgAlt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <span style={{ fontWeight: "600" }}>David Fan</span>.
          All Rights Reserved
        </div>
        <div className="credits">
          Designed by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dstudio.top-oz.com/"
          >
            D Studio
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
