import React from "react";
import { aboutData, aboutHeadData } from "../data/aboutData";

function About({ reference }) {
  return (
    <section ref={reference} id="about">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3>{aboutHeadData.title}</h3>
          <p>{aboutHeadData.content}</p>
        </header>

        <div className="row about-cols">
          {aboutData.map((item) => (
            <div
              key={item.id}
              className="col-md-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="about-col">
                <div className="img">
                  <img src={item.bgImage} alt="" className="img-fluid" />
                  <div className="icon">
                    <i className={item.icon}></i>
                  </div>
                </div>
                <h2 className="title">{item.title}</h2>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
