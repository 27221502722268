import React, { useState } from "react";
import { navData, universityData } from "../data/navData";

function NavBar({ scroll, refs }) {
  const [open, setOpen] = useState(false);
  const [dropdownActive, setDropdowActive] = useState(false);
  const [subDropdownActive, setSubDropdowActive] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const handleDropdowActive = () => {
    setDropdowActive(!dropdownActive);
  };

  const handleSubDropdowActive = () => {
    setSubDropdowActive(!subDropdownActive);
  };

  return (
    <nav id="navbar" className={`navbar ${open && "navbar-mobile"}`}>
      <ul>
        <li>
          <a
            className={`nav-link scrollto
              ${
                scroll === 0 ||
                (scroll > 0 && scroll <= refs[0].current.offsetTop - 290)
                  ? "active"
                  : undefined
              }`}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <i className="bi bi-house-fill"></i>
          </a>
        </li>
        {navData.map((item, index) => (
          <li key={item.id}>
            <a
              className={`nav-link scrollto 
              ${
                scroll > 0 &&
                scroll + 200 >= refs[index].current.offsetTop &&
                scroll + 200 <=
                  refs[index].current.offsetTop +
                    refs[index].current.offsetHeight
                  ? "active"
                  : undefined
              }`}
              onClick={() => {
                window.scrollTo(0, refs[index].current.offsetTop - 30);
              }}
            >
              {item.name}
            </a>
          </li>
        ))}

        <li className="dropdown">
          <a onClick={handleDropdowActive}>
            <span>其它</span> <i className="bi bi-chevron-down"></i>
          </a>
          <ul className={open && dropdownActive ? "dropdown-active" : ""}>
            <li className="dropdown">
              <a onClick={handleSubDropdowActive}>
                <span>澳洲八大名校</span>
                <i className="bi bi-chevron-right"></i>
              </a>
              <ul
                className={open && subDropdownActive ? "dropdown-active" : ""}
              >
                {universityData.map((uni) => (
                  <li key={uni.id}>
                    <a target="_blank" href={uni.link}>
                      {uni.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <a href="#contact">求职应聘</a>
            </li>
          </ul>
        </li>
      </ul>
      <i
        className={`bi ${open ? "bi-x" : "bi-list"} mobile-nav-toggle`}
        onClick={handleMenuClick}
      ></i>
    </nav>
  );
}

export default NavBar;
