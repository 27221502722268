const featuresData = [
  {
    id: 1,
    icon: "bi bi-pencil",
    title: "院校申请",
    content: `澳大利亚各级院校申请，包含高中 (High Shcool)，预科学院 (Foundation)，
    专科学院 (Diploma)，本科 (Bachelor)，研究生 (Master)`,
    bg: "",
  },
  {
    id: 2,
    icon: "bi bi-card-checklist",
    title: "签证办理",
    content: `赴澳大利亚各类签证高效成功办理，包含学生签证，旅游探亲签证，院校上诉、投诉、解释信函，
    移民类签证，以及其他签证相关业务均可咨询办理`,
    bg: "box-bg",
  },
  {
    id: 3,
    icon: "bi bi-graph-up",
    title: "技能培训",
    content: `针对不同的行业需求，各类技能培训课程均有开设，课程内容丰富，
    包含IT编程类，应用开发类，数据分析类，财会以及金融投资类，并给予相关求职指导`,
    bg: "",
  },
];

export default featuresData;
