const servicesHeadData = {
  title: "服务介绍",
  content: `得助教育重细节，抓全面，从学生们的细微处做起。申学，选专业，选课，转学，升学，
  移民知识讲座，职业技能培训，移民申请，各类签证办理。真正做到一站式服务，
  学生在得助享受的不仅是优质的服务，还能收获无忧的留学旅程`,
};

const servicesData = [
  {
    id: 1,
    icon: "bi bi-pencil",
    title: "澳洲院校申学，升学咨询",
    content: `资深教育顾问，澳洲各院校申请经验丰富，擅长处理疑难案例，为同学们在申请中争取最大利益。
      坚持严谨，认真的做事态度，根据同学们的切身情况提供最优建议`,
  },
  {
    id: 2,
    icon: "bi bi-calendar4-week",
    title: "澳洲院校申诉，转学咨询",
    content: `资深教育顾问，擅长帮助同学们处理与院校间的纠纷申诉，成功帮助大量学生们从逆境中力挽狂澜。
      及时向有需要的同学提供转学服务，确保大学能够愉快的完成学业`,
  },
  {
    id: 3,
    icon: "bi bi-journal-check",
    title: "大学选课指导，学习规划",
    content: `资深教育顾问，精通澳洲各个大学的每个专业的课程设置，能向同学们提供精确的，可行性高的，
    选课建议。帮助每一个学生做出合理的课业安排，护航同学们顺利毕业`,
  },
  {
    id: 4,
    icon: "bi bi-card-checklist",
    title: "境内外签证办理",
    content: `专业签证服务团队，精通澳洲各类签证，如学生签，旅游探亲签证等，各类签证申请无一例失败，
      申请处理效率高，与客户保沟通及时，并为签证相关业务提供服务`,
  },
  {
    id: 5,
    icon: "bi bi-bank",
    title: "技术及投资移民代理",
    content: `权威移民代理团队，以高超的专业水平，锲而不舍的精神，为申请人争取最大利益的态度，
    为申请人获取了澳大利亚各类技术移民，投资移民签证和各类其他永居签证`,
  },
  {
    id: 6,
    icon: "bi bi-graph-up",
    title: "行业，职业技能培训",
    content: `汇集各行业精英组成的培训师团队，为同学们开设各类行业培训课程，涉及IT编程，网络应用开发，
      数据分析，财会技能，金融投资等热门领域，助你开启职场生涯`,
  },
];

export { servicesHeadData, servicesData };
