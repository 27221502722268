import React from "react";
import ImgQR from "../assets/footer/weixin.jpg";

function Modal() {
  return (
    <div
      className="modal fade"
      id="callToActionModal"
      tabIndex="-1"
      aria-labelledby="callToActionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              成为得助学员
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center">
            <p>
              请扫描下方二维码添加客服微信，咨询有关澳洲大学选课，
              院校咨询，留学，移民方案及技能培训课程等相关服务
            </p>
            <img style={{ width: "30%" }} src={ImgQR} alt="QR Code" />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="modal-close"
              data-bs-dismiss="modal"
            >
              关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
