import React, { useRef, useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  factsData,
  counterData,
  infoHeadData,
  infoData,
} from "../data/factsData";

function Facts({ scroll }) {
  const factRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(scroll >= factRef.current.offsetTop - 160);
  }, [scroll]);

  return (
    <section id="facts" ref={factRef}>
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3>{factsData.title}</h3>
          <p>{factsData.content}</p>
        </header>

        <div className="row counters">
          {counterData.map((item) => (
            <div key={item.id} className="col-lg-3 col-6 text-center">
              <span>
                {active ? (
                  <CountUp end={item.end} duration={item.duration} />
                ) : (
                  item.end
                )}
              </span>
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="container-fluid mt-5 info-box">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
            <div className="content">
              <h3>{infoHeadData.title}</h3>
              <p>{infoHeadData.content}</p>
            </div>

            <div className="accordion-list">
              <ul>
                {infoData.map((item) => (
                  <li key={item.id}>
                    <a
                      data-bs-toggle="collapse"
                      className="collapsed"
                      data-bs-target={`#${item.target}`}
                    >
                      <span>{item.id}</span> {item.title}
                      <i className="bi bi-caret-down-fill icon-show"></i>
                      <i className="bi bi-caret-up-fill icon-close"></i>
                    </a>
                    <div
                      id={item.target}
                      className="collapse"
                      data-bs-parent=".accordion-list"
                    >
                      <p>{item.content}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-5 align-items-stretch order-1 order-lg-2 facts-img">
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  );
}

export default Facts;
