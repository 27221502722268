import React, { useEffect, useRef, useState } from "react";
import { skillsData, skillsHeadData } from "../data/skillsData";

function Skills({ scroll }) {
  const skillsRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    scroll >= skillsRef.current.offsetTop - 150 && setActive(true);
  }, [scroll]);

  return (
    <section id="skills" ref={skillsRef}>
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h3>{skillsHeadData.title}</h3>
          <p>{skillsHeadData.content}</p>
        </header>

        <div className="skills-content">
          {skillsData.map((item) => (
            <div className="progress" key={item.id}>
              <div
                className={`progress-bar ${item.color}`}
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={active ? { width: item.percentage } : { width: "1px" }}
              >
                <span className="skill">
                  {item.name} <i className="val">{item.percentage}</i>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
