import bgImage1 from "../assets/about/teamwork-bg-1.jpg";
import bgImage2 from "../assets/about/teamwork-bg-2.jpg";
import bgImage3 from "../assets/about/corporate-bg-1.jpg";

const aboutHeadData = {
  title: "关于我们",
  content: `资深的留学顾问团队，精英的培训师团队，专业的移民咨询团队，共同打造着我们
  不断追求卓越的企业文化，以及点滴铸非凡的做事态度，我们承诺对每一个客户及学生负责，选择得助教育，
  让你感受不一样的氛围，体会高端和优质的过程及结果`,
};

const aboutData = [
  {
    id: 1,
    bgImage: bgImage1,
    icon: "bi bi-bar-chart",
    title: "我们的发展",
    content: `得助教育成立于2014年，致力于服务国际留学生，从开始经营至今，我们收到了的大量留学生及留学生家人的好评和认可。
    在不断追求卓越的过程中，我们团队也在不断地壮大，发展。现今，包含咨询顾问，
    培训讲师，及行政人员在内，我们团队已经成长到30+成员。感谢同学们一如既往的信任，依依留学情，得助常相伴。`,
  },
  {
    id: 2,
    bgImage: bgImage2,
    icon: "bi bi-brightness-high",
    title: "我们的文化",
    content: `我们本着诚信，务实，追求卓越的精神，帮助着一代又一代的留学生完成来澳洲的留学，移民梦想，
    为大家的未来发展打造了坚实的基础。在得助，留学生享受的不仅仅是一站式的留学，移民，技能培训等全套服务，
    并且可以收获人生观，价值观及事业观的升华，结实大量的新朋友。选择得助，选择属于你的未来。`,
  },
  {
    id: 3,
    bgImage: bgImage3,
    icon: "bi bi-binoculars",
    title: "我们的视角",
    content: `放眼未来，我们会延续自己优秀的文化和服务态度，真诚的服务好每一位客人和学生。
    我们会为每一位学生设身处地的考虑，拿出最切实可行的个性化留学方案。针对学生性格，及能力，给出专业的指导和建议。
    与此同时，我们还会推陈出新，开设更多符合行业及职场需求的技能培训课程，确保学生能顺利地从校园步入社会。`,
  },
];

export { aboutHeadData, aboutData };
